import { createContext, useContext, useEffect, useState } from "react";
import makeRequest from "../makeRequest";
import { useAuthContext } from "./AuthContext";

const DataContext = createContext({});

export const useDataContext = () => useContext(DataContext);


export const DataProvider = ({ children }) => {

    const medias = [
        'Facebook',
        'Instagram',
    ]
    const postTypes = [
        'text',
        'single-image-post',
        'single-video-post',
        'reel',
        'story-image',
        'story-video',
        // 'multi-images',
        // 'multi-videos',
        // 'multi-posts'
    ]
    const { user } = useAuthContext();
    const [products, setProducts] = useState([]);
    const [channels, setChannels] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(!user) return;
        
        const fetchProducts = async () => {
            setLoading(true);
            try {
                const response = await makeRequest({
                    method: 'GET',
                    url: '/api/products'
                });
                setProducts(response?.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        const fetchChannels = async () => {
            setLoading(true);
            try {
                const response = await makeRequest({
                    method: 'GET',
                    url: '/api/channels?populate=*&fields[0]=id&fields[1]=channel_id&fields[2]=channel_type&fields[3]=display_name'
                });
                setChannels(response?.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchProducts()
        fetchChannels();
    }, [user])


    return (
        <DataContext.Provider value={{
            products,
            medias,
            channels,
            postTypes,
            loading,
            error
        }}>
            {children}
        </DataContext.Provider>
    )
}