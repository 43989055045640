import React from "react";
import { Routes, Route } from "react-router-dom";
import SignIn from "../pages/SignIn/SignIn";
import CalendarPage from "../pages/CalendarPage/CalendarPage";
import SignUp from "../pages/SignUp/SignUp";
import SchedulePost from "../pages/SchedulePost";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import NotFound from "../pages/NotFound";

const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<PublicRoute restricted={true} element={<SignIn/>} />} /> */}
      <Route
        path="/signup"
        element={<PublicRoute restricted={true} element={<SignUp />} />}
      />
      <Route
        path="/login"
        element={<PublicRoute restricted={true} element={<SignIn />} />}
      />

      <Route element={<PrivateRoute />}>
        <Route path="/calendar" element={<CalendarPage />} />
        <Route path="/prompt" element={<SchedulePost />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
