import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';

export default function MUIDrawer({ open, onToggle }) {

    const DrawerList = (
        <Box sx={{ width: 250 }}
            // role="presentation" 
            onClick={onToggle(false)}>
            <List>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to='/calendar'>
                        <Box display='flex' alignItems='center'>
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Calendar" />
                        </Box>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to='/prompt'>
                        <Box display='flex' alignItems='center'>
                            <ListItemIcon>
                                <MailIcon />
                            </ListItemIcon>
                            <ListItemText primary="Create Post" />
                        </Box>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            <Drawer variant="temporary" open={open} onClose={onToggle(false)}>
                {DrawerList}
            </Drawer>
        </div>
    );
}
