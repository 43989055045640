import React from 'react';
import { Dropdown, Button, Menu } from 'antd';

const CustomToolbar = ({ label, onNavigate, onView, filterStatus, setFilterStatus }) => {
  const filterMenu = (
    <Menu
      onClick={(e) => setFilterStatus(e.key)}
      items={[
        { key: '', label: 'All' },
        { key: 'Draft', label: 'Draft' },
        { key: 'Published', label: 'Published' },
        { key: 'Error', label: 'Error' },
        { key: 'Waiting', label: 'Waiting' },
      ]}
    />
  );
  return (
    <div className="rbc-toolbar">
      {/* Navigation buttons */}
      <span className="rbc-btn-group">
         <Dropdown overlay={filterMenu} trigger={['click']}>
          <Button>
            {filterStatus || "Filter"} {/* Show the selected filter */}
          </Button>
        </Dropdown>
      </span>
        <button type="button" onClick={() => onNavigate('PREV')}>
          Prev
        </button>
        <button type="button" onClick={() => onNavigate('TODAY')}>
          Today
        </button>
        <button type="button" onClick={() => onNavigate('NEXT')}>
          Next
        </button>
        
      {/* </span> */}

      {/* Current month/week/day label */}
      <span className="rbc-toolbar-label">{label}</span>

      {/* View switching buttons */}
      <span className="rbc-btn-group">
        <button type="button" onClick={() => onView('month')}>
          Month
        </button>
        <button type="button" onClick={() => onView('week')}>
          Week
        </button>
        <button type="button" onClick={() => onView('day')}>
          Day
        </button>
      </span>
    </div>
  );
};

export default CustomToolbar;
